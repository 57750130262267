/** Reset with sanitize.css **/
@import '../node_modules/sanitize.css/sanitize.css';
@import '../node_modules/sanitize.css/typography.css';
@import '../node_modules/sanitize.css/forms.css';
@import '../node_modules/tiny-slider/src/tiny-slider.scss';

/** Custom **/
// @import '_config/index';
@import 'elastique';

/* general */
@font-face {
    font-family: 'SourceSansPro';
    src: url('sourceSans.woff2') format('woff2'),
        url('sourceSans.woff') format('woff'),
        url('sourceSans.ttf') format('truetype');
    font-weight: 300;
}

:root {
    --primary-color: #000;
    --primary-color-alt: rgb(12, 12, 12);
    --secondary-color: #fff;
    --hightlight-color: #BF2F1A;
    --primary-font-size: 1.4em;
    --secondary-font-size: 3em;
    --medium-font-size: 1.8em;
    --custom-font-size: 1em;
    --grid-gap-x: 25px;
    --grid-gap-y: 25px;
}

@media (max-width: 991.98px) {
    :root {
        --primary-font-size: 1.1em;
        --secondary-font-size: 2.2em;
        --custom-font-size: 0.9em;
        --medium-font-size: 1.6em;
    }
}

* {
    font-weight: 300;
}

body {
    background-color: var(--primary-color);
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
    color: var(--secondary-color);
    font-family: 'SourceSansPro', sans-serif;
}

p {
    font-size: var(--primary-font-size);
    margin: 0 0 0.5em 0;
}

a {
    color: var(--secondary-color);
    font-weight: 300;
    text-decoration: underline;
}

h1 {
    font-size: var(--secondary-font-size);
    line-height: 1.2em;
    margin-bottom: 0.2em;
}

h2 {
    font-size: var(--secondary-font-size);
    line-height: 1.2em;
    margin-bottom: 0.2em;
}

h3 {
    color: var(--secondary-color);
    font-size: var(--medium-font-size);
    margin-bottom: 0.4em;
}

section {
    padding: 70px 0;
}

section.full-width {
    width: 100%;
    position: relative;
}

section.offset-top {
    margin-top: -180px;
}

button {
    box-shadow: none;
    outline: none;
    background: var(--primary-color);
    cursor: pointer;
    padding: 5px 20px;
    color: #fff;
    text-decoration: none;
    font-size: var(--custom-font-size);
    border: 2px solid var(--secondary-color);
}

a.fill {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

li {
    color: var(--secondary-color);
}

.text-center {
    text-align: center;
}

#loading-screen {
    width: 100%;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;
    background-color: var(--primary-color);
    transition: opacity 0.35s ease;
}

#loading-screen.hide {
    opacity: 0;
    pointer-events: none;
}

/* cookie banner */
#cookies-eu-banner {
    position: fixed;
    z-index: 2;
    left: 20px;
    bottom: 20px;
    background-color: var(--primary-color-alt);
    max-width: 600px;
    padding: 25px;
}

#cookie-eu-banner-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-content: center;
}

#cookies-eu-more {
    margin-bottom: 30px;
    text-decoration: underline;
}

#cookie-eu-banner-content > button {
    margin-bottom: 10px;
}

@media (max-width: 991.98px) {
    #cookies-eu-banner {
        left: 0;
        bottom: 0;
        padding: 15px;
    }
}

/* helpers */
.padding-top {
    padding-top: 150px;
}

@media (max-width: 991.98px) {
    .padding-top {
        padding-top: 0;
    }
}

/* grid */
.flex-grid.fixed-width {
    max-width: 1400px;
}

/* images */
.image-container {
    width: 100%;
}

.image-container>picture>img,
.image-container>picture>source {
    width: 100%;
    display: block;
}

.image-container>picture>img,
.image-container>picture>source {
    width: 100%;
    display: block;
}

.image-container.bgr-like {
    height: 100%;
    position: absolute;
    overflow: hidden;
}

.image-container.fixed-bgr {
    clip-path: inset(0);
}

.image-container.bgr-like>picture>img,
.image-container.bgr-like>picture>source {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    object-position: center center;
    width: 100%;
    height: 100%;
}

.image-container.fixed-bgr>picture>img,
.image-container.fixed-bgr>picture>img {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

/* flex */
.flex-container {
    display: flex;
    flex-direction: column;
}

.flex-container.center {
    justify-content: center;
    align-items: center;
    align-content: center;
}

/* header */
#main-header {
    width: 100%;
    min-height: 80vh;
    position: relative;
}

#header-bgr-img {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 0;
}

#header-intro-content {
    padding: 70px 0 100px 0;
}

#header-intro-text {
    position: relative;
    z-index: 1;
    padding: 0 20px;
}

#header-intro-logo {
    position: relative;
    z-index: 1;
    max-width: 450px;
    margin: 0 auto 40px auto;
}

@media (max-width: 991.98px) {
    #header-intro-logo {
        max-width: 250px;
    }
}

/* subpages */
.subpage-box {
    position: relative;
    transition: transform 0.35s ease;
}

.subpage-box:hover {
    transform: scale(1.02);
}

.subpage-content {
    padding: 25px 25px 35px 25px;
    background-color: var(--primary-color-alt);
}

.subpage-title > h3 {
    margin-bottom: 0.2em;
}

.subpage-excerpt,
.subpage-excerpt>p {
    margin: 0;
}

/* quote */

.quote-container {
    border-left: 2px solid var(--secondary-color);
    padding-left: 25px;
}

.quote {
    font-style: italic;
}

/* footer */
footer {
    background: rgb(184, 0, 0);
    background: linear-gradient(45deg, rgba(184, 0, 0, 1) 0%, rgba(219, 83, 21, 1) 100%);
    padding: 40px 0;
}

#footer-list ul,
#copyright-list ul {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

#footer-list ul>li,
#copyright-list ul>li {
    list-style: none;
    margin: 0 10px;
    font-size: var(--custom-font-size);
    color: var(--secondary-color);
    text-decoration: none;
}

#footer-list ul>li>a,
#copyright-list ul>li>a {
    font-size: var(--custom-font-size);
    color: var(--secondary-color);
    text-decoration: none;
}

#copyright-list {
    margin-top: 15px;
}

#copyright-list ul>li,
#copyright-list ul>li>a {
    font-size: var(--custom-font-size);
    text-decoration: none;
}

@media (max-width: 991.98px) {
    #footer-list ul,
#copyright-list ul {
    flex-direction: column;
}

#footer-list ul>li,
#copyright-list ul>li {
    margin-bottom: 6px;
}
}